import { cx } from '@linaria/core';
import { either } from 'fp-ts';
import { pipe } from 'fp-ts/lib/function';
import { queryKeys, useQueryTE } from 'utils/react-query';
import { ReactLeft, reactLeftToJSX, UI_STATES } from 'utils/uiStates/uiStates';
import { errorToReactLeft } from 'utils/fp';
import { Row, Col } from 'components/Grid';
import { fetchAuthBannerTE } from 'api/hubspot/fetchAuthBanner';
import { SyntheticEvent } from 'react';
import { useDataCollection } from 'modules/AnalyticsCollection/AnalyticsCollectionProvider';
import { DefaultBanner } from './DefaultBanner';
import { BannerContent } from './BannerContent';
import { authBannerWrapper, BannerWrapper } from '../styles';

interface HubspotBannerProps extends React.HTMLAttributes<HTMLDivElement> {
  pageName: string;
}

const leftMap = {
  [UI_STATES.connectionError]: () => <DefaultBanner />,
  [UI_STATES.error]: () => <DefaultBanner />,
};

export const HubspotBanner: React.FC<HubspotBannerProps> = ({ pageName, className }): JSX.Element => {
  const { data } = useQueryTE(queryKeys.marketingBanners, fetchAuthBannerTE({}, errorToReactLeft));
  const bannerValuesE = pipe(
    data,
    either.map((response) => (response.results || []).filter((a: HUBSPOT.API.AuthBannerResult) => a.values?.page_name === pageName)),
    either.map((list) => list[0]),
    either.chain((banner: HUBSPOT.API.AuthBannerResult | undefined) =>
      banner && banner.values && banner.values.heading ? either.right(banner) : either.left({ type: UI_STATES.error } as ReactLeft),
    ),
  );
  const { track } = useDataCollection();

  const onClickHandler = (e: SyntheticEvent<HTMLDivElement>): void => {
    const { nodeName } = e.target as HTMLElement;
    if (nodeName === 'BUTTON' || nodeName === 'A') {
      track('Hubspot Banner Clicked', { param1: 'login_page', param2: 'banner_button' });
    }
  };

  return (
    <div className={cx(BannerWrapper, className)}>
      <div className={authBannerWrapper}>
        {pipe(
          bannerValuesE,
          either.fold(reactLeftToJSX(leftMap), (safeBanner) => (
            <Row key={safeBanner.values.page_name} justifyContent="center">
              <Col xs={12} md={10} lg={8} onClick={onClickHandler}>
                <BannerContent banner={safeBanner.values} />
              </Col>
            </Row>
          )),
        )}
      </div>
    </div>
  );
};

import { authHeading, Content, loginBannerDescription, loginBannerImage, buttonAuth } from '../styles';

export const BannerContent: React.FC<{ banner: HUBSPOT.API.AuthBannerValues }> = ({ banner, ...rest }): JSX.Element => {
  const { image, image_height, image_width, heading, description, button_text, button_link } = banner;

  return (
    <div className={Content} {...rest}>
      {image?.url && <img className={loginBannerImage} height={image_height} width={image_width} src={image.url} alt={heading} />}
      {heading && <h2 className={authHeading}>{heading}</h2>}
      {description && <p className={loginBannerDescription}>{description}</p>}
      {button_link && (
        <a className={buttonAuth} href={button_link} target="_blank" rel="noreferrer">
          {button_text}
        </a>
      )}
    </div>
  );
};

import { cx } from '@linaria/core';
import { Image } from 'components/Image';
import { Row, Col } from 'components/Grid';
import { flex } from 'styles/utils';
import { SignupContentWrapper, authHeading, authSubHeading, authDescription, authCheckboxImage, clientLogo } from '../styles';

export const MarketingText = ({ text, ...rest }: { text: string }): JSX.Element => (
  <div className={cx(flex.init, flex.flexWrap.noWrap)} {...rest}>
    <div className={authCheckboxImage} />
    <p className={authDescription}>{text}</p>
  </div>
);

export const DefaultBanner = (): JSX.Element => (
  <Row justifyContent="center">
    <Col xs={12} md={10} lg={8}>
      <div className={SignupContentWrapper}>
        <h2 className={authHeading}>The World&#39;s AI</h2>
        <h3 className={authSubHeading}>Sign up for a free account and gain access now</h3>

        <div>
          <MarketingText text="1,000 free operations per month." />
          <MarketingText text="Pre-trained models to get you started." />
          <MarketingText text="Easy-to-use, drag and drop UI for faster training, evaluation and deployment." />
          <MarketingText text="SOTA transfer learning to build custom models faster." />
        </div>

        <br />
        <h3 className={cx('margin-1-top', authSubHeading)}>Trusted by Enterprises Worldwide</h3>
        <div data-testid="client-logos" className={cx(flex.init, flex.flexWrap.wrap, flex.justifyContent.between)}>
          <Image
            className={clientLogo}
            src="/auth_icons/client-department-of-defense.svg"
            alt="client-dod"
            height="42px"
            width="42px"
            objectFit="cover"
          />
          <Image className={clientLogo} src="/auth_icons/client-nvidia.png" alt="client-nvidia" height="35px" width="80px" objectFit="contain" />
          <Image className={clientLogo} src="/auth_icons/client-canva.svg" alt="client-canva" height="35px" width="80px" objectFit="contain" />
          <Image
            className={clientLogo}
            src="/auth_icons/client-open-table.svg"
            alt="client-open-table"
            height="35px"
            width="120px"
            objectFit="contain"
          />
        </div>
      </div>
    </Col>
  </Row>
);

import { currentYear } from 'components/Footer';
import { footerContainer, footerWrapper, footerLinkWrapper, textGrey, textLinkGreyExternal } from '../styles';

export const Footer: React.FC = ({ children }) => (
  <div className={footerContainer}>
    {children}
    <div className={footerWrapper}>
      <div className={footerLinkWrapper}>
        <a className={textLinkGreyExternal} rel="noreferrer" target="_blank" href="https://clarifai.com/company/about">
          About
        </a>
        <a className={textLinkGreyExternal} rel="noreferrer" target="_blank" href="https://help.clarifai.com/hc/en-us">
          Help Center
        </a>
        <a className={textLinkGreyExternal} rel="noreferrer" target="_blank" href="https://clarifai.com/terms">
          Terms of Service
        </a>
        <a className={textLinkGreyExternal} rel="noreferrer" target="_blank" href="https://clarifai.com/privacy-policy">
          Privacy Policy
        </a>

        <div className={textGrey}>
          &copy;
          {`${currentYear} Clarifai, Inc.`}
        </div>
      </div>
    </div>
  </div>
);

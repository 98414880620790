import { useRouter } from 'next/router';
import type { ParsedUrlQuery } from 'querystring';

export const useUrlWithParams = (url: string) => {
  const router = useRouter();
  const query = router.query as ParsedUrlQuery;
  const params = ['from', 'recovery_path', 'utm_campaign', 'utm_source', 'utm_medium'].reduce((acc, k) => {
    if (k in query) {
      const values = query[k] ? (Array.isArray(query[k]) ? query[k] : [query[k]]) : [];
      (values as unknown[])
        .filter((v) => v)
        .forEach((value) => {
          acc.push(`${k}=${encodeURIComponent(value as string)}`);
        });
    }
    return acc;
  }, [] as string[]);
  return `${url}${params.length ? `?${params.join('&')}` : ''}`;
};
